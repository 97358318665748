
var video1_ST,
    video2_ST,
    video3_ST;
function playVideo(el){
    $(".main_video").addClass("hover");
    $(".main_video-item").removeClass("hover");
    $(".main_video .col").removeClass("hover");
    $(el).addClass("hover");
    $(el).parent(".col").addClass("hover");
    var promise = $("video", el)[0].play();
    if (promise !== undefined) {
        promise.then(_ => {
            
        }).catch(error => {
            console.log("play error");
        })
    }
    return $("video", el)[0].duration;
}
function stopVideo(el){
    $("video", el)[0].pause();
    $("video", el)[0].currentTime = 0;
}
function videoDuration(el){
    return $("video", el)[0].duration;
}
function video3(start3 = true, time3 = 0){
    stopVideo($(".main_video .video-1 .main_video-item"));
    stopVideo($(".main_video .video-2 .main_video-item"));
    if(start3){
        stopVideo($(".main_video .video-3 .main_video-item"));
    }
    var video3_d = $(".main_video .video-3 video")[0].duration * 1000;
    if(time3 > 0){
        time3 = time3 * 1000;
        video3_d = video3_d - time3;
    }
    playVideo($(".main_video .video-3 .main_video-item"));
    video3_ST = window.setTimeout(function(){
        video1();
    }, (video3_d));
}
function video2(start2 = true, time2 = 0){
    stopVideo($(".main_video .video-1 .main_video-item"));
    if(start2){
        stopVideo($(".main_video .video-2 .main_video-item"));
    }
    stopVideo($(".main_video .video-3 .main_video-item"));
    var video2_d = $(".main_video .video-2 video")[0].duration * 1000;
    if(time2 > 0){
        time2 = time2 * 1000;
        video2_d = video2_d - time2;
    }
    playVideo($(".main_video .video-2 .main_video-item"));
    video2_ST = window.setTimeout(function(){
        video3();
    }, (video2_d));
}
function video1(start1 = true, time1 = 0){
    if(start1){
        stopVideo($(".main_video .video-1 .main_video-item"));
    }
    stopVideo($(".main_video .video-2 .main_video-item"));
    stopVideo($(".main_video .video-3 .main_video-item"));
    var video1_d = $(".main_video .video-1 video")[0].duration * 1000;
    if(time1 > 0){
        time1 = time1 * 1000;
        video1_d = video1_d - time1;
    }
    playVideo($(".main_video .video-1 .main_video-item"));
    video1_ST = window.setTimeout(function(){
        video2();
    }, (video1_d));
}
document.addEventListener("DOMContentLoaded", () => {
    if($(".main_video").length != 0){
        setTimeout(function(){
            window.clearTimeout(video1_ST); // "Выключаем" карусель
            window.clearTimeout(video2_ST); // "Выключаем" карусель
            window.clearTimeout(video3_ST); // "Выключаем" карусель
            video1()
        }, 1000);
    }
});

$(".main_video-item").hover(
    function() {
        window.clearTimeout(video1_ST); // "Выключаем" карусель
        window.clearTimeout(video2_ST); // "Выключаем" карусель
        window.clearTimeout(video3_ST); // "Выключаем" карусель
        $(".main_video-item").removeClass("hover");
        $(".main_video .col").removeClass("hover").removeClass("active");
        $(".main_video").addClass("hover");
        $(this).parent(".col").addClass("hover").addClass("active");
        $(this).addClass("hover");
        var promise = $("video", $(this))[0].play();
        if (promise !== undefined) {
            promise.then(_ => {
                // Autoplay started!
            }).catch(error => {
            });
        }
        
    }, function() {
        $(".main_video").removeClass("hover");
        $(".main_video-item").removeClass("hover");
        // $("video", $(this))[0].pause();
        // $("video", $(this))[0].currentTime = 0;
        var parent = $(this).parent(".col");
        if(parent.hasClass("video-1")){
            var t1 = $("video", $(this))[0].currentTime;
            video1(false, t1);
        }else if(parent.hasClass("video-2")){
            var t2 = $("video", $(this))[0].currentTime;
            video2(false, t2);
        }else if(parent.hasClass("video-3")){
            var t3 = $("video", $(this))[0].currentTime;
            video3(false, t3);
        }
    }
);


// Меню
$("header .menu-btn").on("click", function(e){
    e.preventDefault();
    $(".header_catalog-menu").addClass("open");
    $("body").addClass("bg-fon");
    $(".header form.search").removeClass("open");
    return false;
});
$(".header_catalog-menu .close-menu-catalog").on("click", function(e){
    e.preventDefault();
    $(".header_catalog-menu").removeClass("open");
    $("body").removeClass("bg-fon");
    return false;
});
$(document).mouseup( function(e){
    var div = $(".header_catalog-menu");
    if (!div.is(e.target) && div.has(e.target).length === 0 && $("body").hasClass("bg-fon") && $(".header_catalog-menu").hasClass("open")) {
        $(".header_catalog-menu").removeClass("open");
        $("body").removeClass("bg-fon");
    }
});

// модальноке окно
$(".modal-btn").on("click", function(e){
    e.preventDefault();
    $(".modal-block").addClass("open");
    $("body").addClass("bg-fon");
    $(".header form.search").removeClass("open");
    return false;
});
$(".modal-block_close").on("click", function(e){
    e.preventDefault();
    $(".modal-block").removeClass("open");
    $("body").removeClass("bg-fon");
    return false;
});
$(document).mouseup( function(e){
    var div = $(".modal-block");
    if (!div.is(e.target) && div.has(e.target).length === 0 && $("body").hasClass("bg-fon") && $(".modal-block").hasClass("open")) {
        $(".modal-block").removeClass("open");
        $("body").removeClass("bg-fon");
    }
});

$(".video-block").on("click", ".cover-img", function(){
    $this = $(this),
    $video = $("video", $this.parent(".video-block"));
    $this.hide();
    var promise = $video[0].play();
    if (promise !== undefined) {
        promise.then(_ => {
        }).catch(error => {
            console.log("play error");
        })
    }

});

if($(".swiper.main-catalog").length != 0){
    const swiperMainCatalog = new Swiper('.swiper.main-catalog', {
        slidesPerView: 4,
        spaceBetween: 16,
        freeMode: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
        mousewheel: {
            releaseOnEdges: true,
        },
        breakpoints: {
            // when window width is >= 320px
            0: {
                slidesPerView: 1,
            },
            767: {
                slidesPerView: 2,
            },
            1439: {
                slidesPerView: 4,
            }
        }
    });
}
if($(".main-ideas_mobile").length != 0){
    const swiperMainCatalog = new Swiper('.main-ideas_mobile', {
        slidesPerView: 4,
        spaceBetween: 16,
        freeMode: true,
        scrollbar: {
            el: '.main-ideas-scrollbar',
            draggable: true,
        },
        mousewheel: {
            releaseOnEdges: true,
        },
        breakpoints: {
            // when window width is >= 320px
            0: {
                slidesPerView: 1,
            },
            767: {
                slidesPerView: 2,
            },
            1439: {
                slidesPerView: 4,
            }
        }
    });
}
if($(".swiper.catalog-element_top-carusel").length != 0){
    var paginationElementImg = [];
    $('.swiper.catalog-element_top-carusel .swiper-pagination .swiper-slide').each(function (index, element) {
        paginationElementImg[index] = element.innerHTML;
    });
    const swiperElementCatalog = new Swiper('.swiper.catalog-element_top-carusel', {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">'+ (paginationElementImg[index]) +'</span>';
            },
        },
    });
}
if($(".swiper.documents").length != 0){
    const swiperElementCatalog = new Swiper('.swiper.documents', {
        slidesPerView: 3,
        spaceBetween: 16,
        navigation: {
            nextEl: ".swiper-button-next-doc",
            prevEl: ".swiper-button-prev-doc",
        },
    });
}

window.addEventListener("scroll", function () {
    if (window.pageYOffset > 10) {
        $("body").addClass("header-scroll");
    } else {
        $("body").removeClass("header-scroll");
    }
});

$(".catalog-sections_menu .has-child>a").on("click", function(e){
    e.preventDefault();
    $(this).parent(".has-child").toggleClass("open");
    $(".catalog_menu-child", $(this).parent(".has-child")).slideToggle();
    return false;
});

$(".header_catalog-menu .has-child>a").on("click", function(e){
    e.preventDefault();
    $(".catalog_menu-child", $(this).parent(".has-child")).toggleClass("open");
    return false;
});


function formatState (state) {
    if (!state.id) {
        return state.text;
    }
    console.log(state.text);
    var $state = state.text;
    return $state;
};
$('.select2').each(function (index, element) {
    var $this = $(this);
    $this.select2({
        allowClear: true,
        width: "100%",
        minimumResultsForSearch: Infinity,
        templateSelection: formatState,
        closeOnSelect: false
    });
});

jQuery(window).scroll(function(){
    var $sections = $('.menu-element');
    $sections.each(function(i,el){
        var top  = $(el).offset().top - 120;
        var bottom = top +$(el).height();
        var scroll = $(window).scrollTop();
        var id = $(el).attr('id');
        if( scroll > top && scroll < bottom){
            $('ul.left-menu a.active').removeClass('active');
            $('ul.left-menu a[href="#'+id+'"]').addClass('active');
        }
    });
});

$("ul.left-menu").on("click", "a", function (e) {
    // исключаем стандартную реакцию браузера
    e.preventDefault();
    // получем идентификатор блока из атрибута href
    var id  = $(this).attr('href'),
    // находим высоту, на которой расположен блок
        top = $(id).offset().top - 80;

    // анимируем переход к блоку, время: 800 мс
    $('body,html').animate({scrollTop: top}, 800);
});

$(".questions-menu").on("click", ".has-child>a", function(e){
    e.preventDefault();
    var $this = $(this),
        $parent = $($this).parent();
    $(".questions-menu .has-child").removeClass("open");
    $parent.addClass("open");
    return false;
});
$(".questions-menu").on("click", ".has-child ul li a", function(e){
    e.preventDefault();
    var $this = $(this),
        $target = $($this).attr("href");
    
    $(".questions-block .question").removeClass("active");
    $(".questions-menu .open-quest").removeClass("active");
    $($target).addClass("active");
    $this.addClass("active");
    // console.log($target);
    return false;
});

$("#header-search").keyup(function(){
    var value = $('#header-search').val();
    if(value.length > 2){
        $("#header-search").parent().addClass("open");
    }else{
        $("#header-search").parent().removeClass("open");
    }
});

$(".cookies .btn-arrow").on("click", function(e){
    e.preventDefault();
    $.cookie('cookies', 'Y');
    $(".cookies").removeClass("open");
    return false;
});
if ($.cookie('cookies') == null ) {
    $(".cookies").addClass("open");
}
$(".form-success_close").on("click", function(e){
    e.preventDefault();
    $(this).parent().hide();
    return false;
});

$('.form-control').each(function (index, element) {
    let div = document.createElement('div');
    div.className = "placeholder";
    div.innerHTML = element.placeholder;
    $(element).removeAttr("placeholder");
    element.after(div);
});
$(".catalog-menu_back").on("click", function(e){
    e.preventDefault();
    $(this).closest(".catalog_menu-child").removeClass("open");
    return false;
});